<template>
  <div class="mb-3">
    <div class="form-selectgroup form-selectgroup-boxes d-flex flex-column">
      <label
        class="form-selectgroup-item flex-fill"
        v-for="card in stored"
        :key="`stored-card-${card.paymentProfileId}`"
      >
        <input
          type="radio"
          name="form-payment"
          :value="card.paymentProfileId"
          class="form-selectgroup-input"
          v-model="selected"
        />
        <div class="form-selectgroup-label d-flex align-items-center p-3">
          <div class="me-3">
            <span class="form-selectgroup-check"></span>
          </div>
          <div class="w-100 d-flex align-items-center">
            <div class="flex-grow me-auto">
              <span
                v-if="card.cardType"
                :class="[
                  'payment',
                  'payment-dark',
                  `payment-provider-${card.cardType.toLowerCase()}-dark`,
                  'payment-xs',
                  'me-2',
                ]"
              ></span>
              <span v-else>
                <strong>Card</strong>
              </span>
              ending in <strong>{{ card.cardNumber.slice(-4) }}</strong>
            </div>
            <div v-if="card.default">
              <small><i>(Preferred payment method)</i></small>
            </div>
          </div>
        </div>
      </label>
      <label class="form-selectgroup-item flex-fill">
        <input
          type="radio"
          name="form-payment"
          value="custom"
          class="form-selectgroup-input"
          v-model="selected"
        />
        <div class="form-selectgroup-label d-flex align-items-center p-3">
          <div class="me-3">
            <span class="form-selectgroup-check"></span>
          </div>
          <div>Use a different card</div>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "account.billing.select-stored-payment-method",
  props: {
    stored: Array,
    value: String,
  },
  data() {
    return {
      selected: null,
    };
  },
  methods: {
    setDefaultCard() {
      this.stored.forEach((card) => {
        if (card.default) {
          this.selected = card.paymentProfileId;
        }
      });
    },
  },
  watch: {
    stored() {
      this.setDefaultCard();
    },
    selected() {
      this.$emit("input", this.selected);
    },
  },
  mounted() {
    this.setDefaultCard();
  },
};
</script>
