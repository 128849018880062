<template>
  <div v-if="loaded">
    <div v-if="errormessage">
      {{ errormessage }}
    </div>
    <div v-else-if="displaySoldOutWarning || displayInActiveInventoryWarning">
      <div class="col-md-12 card card-md" v-if="displaySoldOutWarning">
        <div class="card-status-top bg-danger"></div>
        <div class="card-body">
          <div class="col-md-12">
            <div class="col-md-6">
              <p class="h3">
                We are unable to complete your order at this time. Please
                contact Customer Service at 1.866.627.2467 or email
                <a href="mailto:advertisersupport@mediabrains.com">
                  advertisersupport@mediabrains.com
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="col-md-12 card card-md"
        v-if="displayInActiveInventoryWarning"
      >
        <div class="card-status-top bg-danger"></div>
        <div class="card-body">
          <div class="col-md-12">
            <div class="col-md-6">
              <p class="h3">
                We are unable to complete your order at this time. Please
                contact Customer Service at 1.866.627.2467 or email
                <a href="mailto:advertisersupport@mediabrains.com">
                  advertisersupport@mediabrains.com
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="page-body">
      <div class="container">
        <div class="row">
          <div class="card card-lg">
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-md-12 text-center">
                  <img
                    v-for="siteLogoDTO in displaySiteLogoDTOs"
                    v-bind:key="siteLogoDTO.rowID"
                    :src="siteLogoDTO.siteImageName"
                    class="me-1"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-5 mb-md-0">
                  <div class="">
                    <h2>Renewal Summary</h2>

                    <div class="mb-3">
                      <ul class="list-group">
                        <li
                          class="list-group-item d-flex align-items-center"
                          v-for="item in displayInvoiceItemsDTOs"
                          v-bind:key="`inventory-item-${item.rowID}`"
                        >
                          <div class="me-auto">
                            <div v-if="item.type.toLowerCase() !== 'other'">
                              <span>
                                {{ item.unitText.toLowerCase() }}:
                                <strong>{{ item.type }}</strong>
                              </span>
                            </div>
                            <div v-else>
                              <span>
                                {{ item.unitText.toLowerCase() }}
                              </span>
                            </div>
                            <div>
                              <span
                                class="text-muted small"
                                :class="{
                                  'text-nowrap':
                                    item.type.toLowerCase() === 'other',
                                }"
                              >
                                {{ item.itemName }}
                              </span>
                            </div>
                            <div class="mt-2" v-if="item.promoDescription">
                              <p
                                class="small text-muted mb-0 ps-2 border-primary-lt border-start border-2"
                              >
                                {{ item.promoDescription }}
                              </p>
                            </div>
                          </div>
                          <div class="ms-2 text-right">
                            <span class="">{{ item.amount }}</span>
                          </div>
                        </li>
                        <li
                          class="list-group-item d-flex align-items-center justify-content-center"
                        >
                          <div class="me-auto">
                            <h3 class="mb-0">Renewal Total:</h3>
                          </div>
                          <div class="ms-2 text-right">
                            {{ this.money(this.totalamount) }}
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div class="mb-3" v-if="this.specialterms">
                      <div class="alert alert-info" role="alert">
                        <div class="d-flex justify-content-start">
                          <div>
                            <img src="@/assets/info.svg" />
                          </div>
                          <div>&nbsp;{{ this.specialterms }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-7 order-md-first">
                  <div class="">
                    <h2>Verify your renewal information</h2>

                    <div
                      class="alert alert-danger"
                      v-if="storedPaymentMethods.length == 0"
                    >
                      You have no payment methods on your account. To ensure
                      your listings are not interrupted, please add a payment
                      method below.
                    </div>
                    <div class="alert alert-info" v-else>
                      Your renewal will be processed using your preferred
                      payment method below.
                    </div>

                    <div v-if="storedPaymentMethods.length > 0">
                      <div
                        class="d-flex flex-wrap align-items-center justify-content-between"
                      >
                        <h3>Stored payment methods</h3>
                        <a
                          class=""
                          href="#"
                          v-if="freeTrial"
                          v-b-tooltip="
                            'Your credit card will not be charged during your free trial period. We will send you a reminder 5 days before your free trial renews.'
                          "
                        >
                          <span class="badge bg-blue-lt badge-pill">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="icon"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              stroke-width="2"
                              stroke="currentColor"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <path
                                d="M8 8a3.5 3 0 0 1 3.5 -3h1a3.5 3 0 0 1 3.5 3a3 3 0 0 1 -2 3a3 4 0 0 0 -2 4"
                              ></path>
                              <line x1="12" y1="19" x2="12" y2="19.01"></line>
                            </svg>
                          </span>
                        </a>
                      </div>
                      <select-stored-payment-method
                        :stored="storedPaymentMethods"
                        v-model="selectedPaymentMethod"
                      ></select-stored-payment-method>
                    </div>
                    <div class="alert alert-danger" v-if="cardError">
                      <strong>
                        There has been an error validating the card you entered.
                      </strong>
                      Please check your card details and try again.
                    </div>

                    <billing-details-form
                      v-model="details"
                      v-if="
                        storedPaymentMethods.length == 0 ||
                        selectedPaymentMethod == 'custom'
                      "
                    ></billing-details-form>

                    <div class="d-grid">
                      <a
                        href="#"
                        @click.prevent="saveCard"
                        :class="['btn', 'btn-primary', valid ? '' : 'disabled']"
                        v-if="!saving"
                      >
                        <span v-if="selectedPaymentMethod != 'custom'">
                          Set as preferred payment method
                        </span>
                        <span v-else> Add Card </span>
                      </a>
                      <a
                        href="#"
                        :class="['btn', 'btn-primary', 'disabled']"
                        @click.prevent
                        v-else
                      >
                        <span
                          class="spinner-border spinner-border-sm me-2"
                          role="status"
                        ></span>
                        Saving...
                      </a>
                    </div>
                  </div>

                  <upgrade-unavailable></upgrade-unavailable>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 pt-4">
            <p class="text-muted">
              <!-- Download SVG icon from http://tabler-icons.io/i/lock -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="icon me-1"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <rect x="5" y="11" width="14" height="10" rx="2" />
                <circle cx="12" cy="16" r="1" />
                <path d="M8 11v-4a4 4 0 0 1 8 0v4" />
              </svg>
              <span>
                This site utilizes SSL (Secured Socket Layer) encryption to
                secure your information.
              </span>
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <p class="text-muted">
              If you need any assistance, please call our Online Marketing
              Specialists at (866) 627-2467, or email your request to
              <a href="mailto:advertisersupport@mediabrains.com">
                advertisersupport@mediabrains.com
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-center pt-2" v-else>
    <span
      class="spinner-border spinner-border-sm me-2"
      role="status"
      ></span>
      Loading
  </div>
</template>

<style lang="scss" scoped>
.clamp-vh {
  max-height: 100vh;
}

.limit-width {
  max-width: 75%;
}

.conditions {
  text-decoration: underline;
}

.bottomborder {
  border-bottom-color: #ffffff;
}
.row {
  padding-left: 5px;
}
.inv-size {
  max-width: 1400px;
}
.table thead th {
  font-weight: bold !important;
  color: #000000;
}

.non-mobile-nowrap {
  white-space: nowrap !important;
}

@media (max-width: 420px) {
  .inv-mobile {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .text-nowrap {
    white-space: normal !important;
  }

  .text-center {
    text-align: left !important;
  }

  .table > :not(caption) > * > *,
  .markdown > table > :not(caption) > * > * {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }

  .row {
    padding-left: 0px !important;
  }
}
</style>

<script>
import BillingService from "../../services/BillingService";
import BillingDetailsForm from "../../components/Account/Billing/BillingDetailsForm";
import SelectStoredPaymentMethod from "../../components/Account/Billing/SelectStoredPaymentMethod.vue";
import UpgradeUnavailable from "../../components/Upgrade/UpgradeUnavailable.vue";

import TrackingService from "../../services/TrackingService";

import { format, addMonths } from "date-fns";

export default {
  name: "Renewal",
  components: {
    BillingDetailsForm,
    SelectStoredPaymentMethod,
    UpgradeUnavailable,
  },
  data: () => {
    return {
      errormessage: "",
      formErrors: {},
      invoiceDTO: [],
      invoiceItemsDTOs: [],
      siteLogoDTOs: [],
      existingInvoice: [],
      existingInvoicesItems: [],
      existingSiteLogos: [],
      lblAdvertiserInfo: "",
      lblInvoiceDate: "",
      lblInvoiceID: "",
      paidimageurl: "",
      totalamount: 0,
      invoiceid: "",
      incidentid: "",
      insertionorderid: "",
      securitycheck: "",
      ispaid: false,
      issoldout: false,
      isinactiveitem: false,
      installmentduration: 0,
      submittingPayment: false,
      paymentSuccess: false,
      paymentError: false,
      checkoutCard: {},
      referencenumber: "",
      chargedamount: 0,
      cardlastfour: "",
      confirmationMessage: "",
      transactionCompleteMessage: "",
      maxordertotal: 0,
      soldout: "",
      inactiveitem: "",
      specialterms: "",
      rows: 0,

      companyId: null,
      contactId: null,
      repId: null,

      loaded: false,
      saving: false,
      selectedPaymentMethod: null,
      storedPaymentMethods: [],
      cardError: false,
      details: {
        company: null,
        cardInfo: null,
        cardValid: false,
        cardNumber: null,
        cardCode: null,
        expiration: null,
        fullName: null,
        firstName: null,
        lastName: null,
        address: null,
        region: null,
        country: null,
        zip: null,
      },
    };
  },
  methods: {
    getFormattedDate(thedate) {
      let year = new Date(thedate).getFullYear();
      let month = (1 + new Date(thedate).getMonth())
        .toString()
        .padStart(2, "0");
      let day = new Date(thedate).getDate().toString().padStart(2, "0");
      return month + "/" + day + "/" + year;
    },

    money(amount) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });

      return formatter.format(amount);
    },

    validate(item) {
      if (item === "all" || item === "soldout") {
        if (this.issoldout) {
          this.$set(this.formErrors, "soldout", true);
        } else {
          this.$set(this.formErrors, "soldout", false);
        }
      }
      if (item === "all" || item === "inactiveitem") {
        if (this.isinactiveitem) {
          this.$set(this.formErrors, "inactiveitem", true);
        } else {
          this.$set(this.formErrors, "inactiveitem", false);
        }
      }
    },

    async LoadInvoice() {
      let invoiceDTO;

      if(this.invoiceid)
      {
        let invoiceFilter = {
          InvoiceID: this.invoiceid
        };

        invoiceDTO = await BillingService.loadInvoice(invoiceFilter);
      }
      else
      {
        let insertionOrderFilter = {
          InsertionOrderID: this.insertionorderid,
        };

        invoiceDTO = await BillingService.loadInsertionOrder(insertionOrderFilter);

        if(invoiceDTO.invoiceID > 0)
        {
          this.invoiceid = invoiceDTO.invoiceID.toString();
        }

      }

      // get total amount of invoice
      this.totalamount = invoiceDTO.salesAmount;
      this.incidentid = invoiceDTO.incidentID;
      this.ispaid = invoiceDTO.paid;

      this.companyId = invoiceDTO.companyId;
      this.contactId = invoiceDTO.contactID;
      this.repId = invoiceDTO.repID;
      this.specialterms = invoiceDTO.specialTerms;

      if (invoiceDTO.invoiceGuid) {
        this.securitycheck = invoiceDTO.invoiceGuid.substring(0, 3);
      }

      // save invoiceDTO
      this.existingInvoice = invoiceDTO;
      this.details.company = {
        companyid: invoiceDTO.companyId,
      };
    },

    async LoadInvoiceItems() {
      
      let invoiceItemsDTOs;

      if(this.iid)
      {
        let invoiceFilter = {
          InvoiceID: this.invoiceid,
        };

        invoiceItemsDTOs = await BillingService.loadInvoiceItems(
          invoiceFilter
        );
      }
      else{
        let insertionOrderFilter = {
          InsertionOrderID: this.insertionorderid,
        };

        invoiceItemsDTOs = await BillingService.LoadInsertionOrderItems(
          insertionOrderFilter
        );
      }

      // interate through press releases
      invoiceItemsDTOs.forEach((n) => {
        // incrment rows
        this.rows++;

        // format money
        n.amount = this.money(n.amount);

        // set installment duration
        this.installmentduration = n.installmentDuration;

        // build rowid
        n.rowID = this.rows;
      });

      // save invoiceItemsDTOs
      this.existingInvoicesItems = invoiceItemsDTOs;
    },

    async LoadSiteLogos() {

      let invoiceFilter = {
        InvoiceID: this.invoiceid,  
        InsertionOrderID: this.insertionorderid,
      };

      let siteLogoDTOs = await BillingService.loadSiteLogos(invoiceFilter);

      // interate through press releases
      siteLogoDTOs.forEach((n) => {
      // incrment rows
      this.rows++;

      // format site image
      n.siteImageName = process.env.VUE_APP_SITE_LOGO_URL + n.siteImageName;

      // build rowid
      n.rowID = this.rows;
    });

    // save siteLogoDTOs
    this.existingSiteLogos = siteLogoDTOs;
    
    },

    async ProcessUrl() {
      // invoiceid
      this.invoiceid = this.$route.query.invoiceid;
      this.ioid = this.$route.query.ioid;

      let ispaid = false;
      let invoiceFilter;

      // check if invoiceid found
      if (this.invoiceid) {

        invoiceFilter = {
          InvoiceID: this.invoiceid,
        };
        
        // check if invoice paid
        ispaid = await BillingService.isPaidInvoice(invoiceFilter); 
      }

      if(this.ioid)
      {
        // get InsertionOrderID
        this.insertionorderid = this.ioid
        .toString()
        .substring(0, this.ioid.toString().length - 3);

        invoiceFilter = {
          InsertionOrderID: this.insertionorderid,
        };

        // check if invoice exists for IO and is paid
        ispaid = await BillingService.isPaidInvoice(invoiceFilter);
      }

      // will load from invoiceid or insertionorderid
      await this.LoadInvoice();
      
      if (ispaid) {
        // goto to invdtl as invoice already paid
        this.$router.push(
          `/billing/invdtl?iid=${this.invoiceid}${this.securitycheck}`
        );
      } else {
        // load the invoice
        await this.LoadInvoiceItems();
        await this.LoadSiteLogos();
        await this.loadStoredPaymentMethods();
        this.loaded = true;
      }
    },


    async loadStoredPaymentMethods() {
      let payment = await BillingService.getPaymentMethods({
        companyid: this.existingInvoice.companyId,
      });
      this.storedPaymentMethods = payment;
      if (this.storedPaymentMethods.length == 0) {
        this.selectedPaymentMethod = "custom";
      }
    },

    async addPaymentMethod() {
      this.details.company = {
        companyid: this.companyId,
      };
      this.details.cardInfo = null;
      let add = await BillingService.addPaymentMethod(this.details);
      if (add !== "") {
        return true;
      } else {
        return false;
      }
    },

    async setDefaultPaymentMethod() {
      this.details.company = {
        companyid: this.companyId,
      };
      this.details.cardInfo = {
        paymentProfileId: this.selectedPaymentMethod,
      };
      let setDefault = await BillingService.setDefaultPaymentMethod(
        this.details
      );
      if (setDefault !== "" && setDefault !== null) {
        return true;
      } else {
        return false;
      }
    },

    async saveCard() {
      this.saving = true;

      if (this.selectedPaymentMethod == "custom") {
        let added = await this.addPaymentMethod();
        if (added) {
          await this.loadStoredPaymentMethods();
          this.$nextTick(() => (this.saving = false));
        } else {
          this.error = true;
          this.saving = false;
        }
      } else {
        // set as default
        let setDefault = await this.setDefaultPaymentMethod();
        if (setDefault) {
          await this.loadStoredPaymentMethods();
          this.$nextTick(() => (this.saving = false));
        } else {
          this.saving = false;
        }
      }
    },

    last(name) {
      let parts = name.split("->");
      return parts[parts.length - 1];
    },
  },

  computed: {
    trialStart() {
      return format(new Date(), "MMMM d, yyyy");
    },
    trialEnd() {
      return format(addMonths(new Date(), 1), "MMMM d, yyyy");
    },

    freeTrial() {
      return (
        this.existingInvoice.isTrial && this.existingInvoice.salesAmount < 0.01
      );
    },

    displayInvoiceItemsDTOs() {
      return this.existingInvoicesItems;
    },

    displaySiteLogoDTOs() {
      return this.existingSiteLogos;
    },

    displaySoldOutWarning() {
      return this.issoldout;
    },

    displayInActiveInventoryWarning() {
      return this.isinactiveitem;
    },

    valid() {
      return (
        (this.details.cardValid &&
          this.details.cardNumber &&
          this.details.cardCode &&
          this.details.expiration &&
          this.details.firstName &&
          this.details.lastName &&
          this.details.address &&
          this.details.zip) ||
        (this.selectedPaymentMethod != "custom" &&
          this.storedPaymentMethods.length > 0)
      );
    },
  },

  async mounted() {
    await this.ProcessUrl();

    TrackingService.track("viewed renewal pay by credit card", {
      companyId: this.companyId,
      contactId: this.contactId,
      repId: this.repId,
    });
  },
};
</script>
